// AppContext.js
import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [showContent, setShowContent] = useState(false);

  return (
    <AppContext.Provider value={{ data, setData, showContent, setShowContent }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppProvider, useAppContext };
