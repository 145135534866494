// src/features/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Check the initial theme mode from localStorage
const initialMode = localStorage.getItem('theme') || 'light';
const initialColorTh = initialMode === 'dark' ? 'oklch(0.253267 0.015896 252.418)' : 'white';

const initialState = {
  mode: initialMode,
  colorth: initialColorTh,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.mode = action.payload;
      if (state.mode === 'dark') {
        state.colorth = 'oklch(0.253267 0.015896 252.418)';
        localStorage.setItem('theme', 'dark');
      } else {
        state.colorth = 'white';
        localStorage.setItem('theme', 'light');
      }
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
